import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import { IEducationEvent } from "@/interfaces/IEducationEvent";

const crudService = new CrudService();
const URL = "/prices";

@Component
export default class PriceRequestMixin extends Vue {
  protected prices: Array<IEducationEvent> = [];
  protected pricesSuccess = false;
  protected pricesError: Record<any, any> | null = null;
  protected pricesLoading = false;

  protected priceAddonsByStudent = [];
  protected priceAddonsByStudentSuccess = false;
  protected priceAddonsByStudentError: Record<any, any> | null = null;
  protected priceAddonsByStudentLoading = false;

  protected pricesByStudent = [];
  protected pricesByStudentSuccess = false;
  protected pricesByStudentsError: Record<any, any> | null = null;
  protected pricesByStudentLoading = false;

  public async fetchPricesByStudentEducation(educationId: number): Promise<void> {
    this.pricesLoading = true;
    this.pricesSuccess = false;
    this.pricesError = null;
    return await crudService
      .findAll({
        resource: `${URL}/by-student-education/${educationId}`,
      })
      .then((response: AxiosResponse) => {
        this.prices = response.data;
      })
      .catch((error: AxiosError) => {
        this.pricesError = error.response?.data;
      })
      .finally(() => {
        this.pricesLoading = false;
      });
  }

  protected async fetchPriceAddonsByStudent(id: number): Promise<void> {
    this.priceAddonsByStudentLoading = true;
    this.priceAddonsByStudentSuccess = false;
    this.priceAddonsByStudentError = null;
    return await crudService
      .findOne({
        resource: `${URL}/addons-by-student`,
        id: id,
      })
      .then((response: AxiosResponse) => {
        this.priceAddonsByStudent = response.data;
      })
      .catch((error: AxiosError) => {
        this.priceAddonsByStudentError = error.response?.data;
      })
      .finally(() => {
        this.priceAddonsByStudentLoading = false;
      });
  }

  protected async fetchPricesByStudent(id: number): Promise<void> {
    this.pricesByStudentLoading = true;
    this.pricesByStudentSuccess = false;
    this.pricesByStudentsError = null;
    return await crudService
      .findOne({
        resource: `${URL}/by-student`,
        id: id,
      })
      .then((response: AxiosResponse) => {
        this.pricesByStudent = response.data;
      })
      .catch((error: AxiosError) => {
        this.pricesByStudentsError = error.response?.data;
      })
      .finally(() => {
        this.pricesByStudentLoading = false;
      });
  }
}
