


















import { Component, Provide } from "vue-property-decorator";
import StudentForm from "@/views/Student/StudentForm.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import StudentStatusMixin from "@/mixins/StudentStatusMixin";
import KeepAliveMixin from "@/mixins/KeepAliveMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import PriceRequestMixin from "@/mixins/Request/PriceRequestMixin";
import StudentEducationRequestMixin from "@/mixins/Request/StudentEducationRequestMixin";
import student from "@/router/Student";
import { formatStudentName } from "@/utils/NameUtil";
import TabMixin from "@/mixins/TabMixin";

const StudentModule = namespace("student");
const ProductModule = namespace("product");
const MainTabsModule = namespace("main-tabs");

@Component({
  components: { StudentForm },
})
export default class StudentEdit extends mixins(
  StudentStatusMixin,
  StudentMixin,
  KeepAliveMixin,
  StudentRequestMixin,
  PriceRequestMixin,
  StudentEducationRequestMixin,
  TabMixin
) {
  public name = "StudentEdit";

  @StudentModule.Action("update")
  public update!: (options: ICrudOptions) => Promise<void>;

  @StudentModule.Getter("getIsLoading")
  public getIsLoading!: boolean;

  @ProductModule.Action("bookAddons")
  public productAddons: any;

  @ProductModule.Getter("getSuccess")
  public productAddonsSuccess!: boolean;

  @MainTabsModule.Action("pushTabHistory")
  public pushTabHistoryAction: any;

  @MainTabsModule.Action("removeTabHistoryById")
  public removeTabHistoryAction: any;

  @MainTabsModule.Action("addTab")
  public addTabAction: any;

  @MainTabsModule.Action("removeTab")
  public removeTabAction: any;

  @MainTabsModule.Action("setActive")
  public setActiveTabAction: any;

  public registrationInfo: any = null;

  public internalLoading = false;

  private id: number | undefined = undefined;
  private fullPath: string | undefined = undefined;

  public async onSave(data: { student: Record<any, any>; educations: Array<Record<any, any>>; bookedAddons: Array<any>; file: File | null }): Promise<void> {
    this.internalLoading = true;

    try {
      const studentObject = this.studentObject({
        id: this.id,
        ...data.student,
      });

      let obj: {
        student: any;
        educations?: any;
        newChecklistTemplateId?: any;
      } = {
        student: studentObject,
      };

      const tab = {
        id: this.$route.path,
        label: formatStudentName(studentObject.firstName, studentObject.lastName),
        route: this.$route.path,
        customLabel: true,
        name: this.$route.name,
        trans: this.$route.meta?.trans || "sidebar.student",
      };
      this.removeTabHistoryAction(tab);
      this.pushTabHistoryAction(tab);

      this.removeTabAction(tab);
      this.addTabAction(tab);
      this.setActiveTabAction(tab);

      let educations = [];
      for (const education of data.educations) {
        let educationObject = this.educationObject({
          ...education,
          studentId: this.id,
        });
        if (education.id) {
          if (this.isCostBearerIdBillingAddress(education.costBearerTypeId)) {
            let billingAddressObject;
            if (education.billingAddressId) {
              billingAddressObject = this.billingAddressObject({
                ...education,
                id: education.billingAddressId,
                studentEducationId: education.id,
              });
            } else {
              billingAddressObject = this.billingAddressObject({
                ...education,
                id: null,
                studentEducationId: education.id,
              });
            }
            educationObject.billingAddress = billingAddressObject;
          }
        } else {
          let billingAddressObject;
          if (this.isCostBearerIdBillingAddress(education.costBearerTypeId)) {
            billingAddressObject = this.billingAddressObject({
              studentEducationId: null,
              ...education,
            });
          }

          educationObject.billingAddress = billingAddressObject;
        }
        educationObject.automaticPaymentEnabled = education.automaticPaymentEnabled;
        educations.push(educationObject);
      }

      obj.educations = educations;
      obj.newChecklistTemplateId = null;

      if (data.bookedAddons.length > 0) {
        await this.productAddons({ data: data.bookedAddons, id: this.id });
      }

      await this.update({
        id: this.id,
        data: obj,
        resource: "students/update-with-educations",
      });

      if (data.file && this.id) {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("studentId", String(this.id));
        await this.uploadAvatar(formData);
      }

      if (this.id) {
        await this.fetchStudentEducationsWithGrantingTypeAndKeyNumbers(this.id);
        await this.fetchPriceAddonsByStudent(this.id);
      }

      if (this.success) {
        this.$root.$emit("refresh-students-table");
        this.$root.$emit("refresh-student-info");
        this.closeTab(this.fullPath ?? "", this.name);
      }
      this.internalLoading = false;
    } catch (exception) {
      console.error(exception);
      this.internalLoading = false;
    }
  }

  protected fetchData(): void {
    if (this.id) {
      this.fetchStudentEducationsWithGrantingTypeAndKeyNumbers(this.id);
      this.fetchPricesByStudent(this.id);
      this.fetchPriceAddonsByStudent(this.id);
      this.fetchStudent(this.id);
    }
  }

  public get getStudentName(): string {
    return formatStudentName(this.student?.firstName, this.student?.lastName);
  }

  public mounted(): void {
    this.id = Number(this.$route.params.id);
    this.fullPath = String(this.$route.fullPath);
    if (this.$route.params.registration) {
      this.registrationInfo = this.$route.params.registration;
    }

    this.fetchData();

    this.$root.$on("refresh-student-education", () => {
      this.$nextTick(() => {
        if (this.id) {
          this.fetchStudentEducationsWithGrantingTypeAndKeyNumbers(this.id);
          this.fetchPriceAddonsByStudent(this.id);
        }
      });
    });
  }

  public beforeDestroy(): void {
    this.registrationInfo = null;
  }

  @Provide("editEducation")
  protected async editEducation(education: any) {
    const billingAddressObject = {
      id: education.billingAddressId ? education.billingAddressId : null,
      studentEducationId: education.id,
      title: education.billingAddressTitle ? education.billingAddressTitle : "",
      firstName: education.billingAddressFirstName ? education.billingAddressFirstName : "",
      lastName: education.billingAddressLastName ? education.billingAddressLastName : "",
      postalCode: education.billingAddressPostalCode ? education.billingAddressPostalCode : "",
      location: education.billingAddressLocation ? education.billingAddressLocation : "",
      streetAndNumber: education.billingAddressStreetAndNumber ? education.billingAddressStreetAndNumber : "",
    };

    const dataObj = {
      id: education.id,
      studentId: this.$route.params.id,
      mainLicenseClass: education.mainLicenseClass ? education.mainLicenseClass : null,
      licenseClassWithKeyNumber: education.licenseClassWithKeyNumber ? education.licenseClassWithKeyNumber : null,
      bf17: education.bf17,
      combined: education.combined,
      additionalClass: education.additionalClass,
      fillingDate: education.fillingDate,
      grantingType: education.grantingType,
      paymentWorkflow: education.paymentWorkflow,
      automaticPaymentEnabled: education.automaticPaymentEnabled,
      firstExistingLicenseClass: education.firstExistingLicenseClass,
      secondExistingLicenseClass: education.secondExistingLicenseClass,
      firstExistingDrivingLicenseNumber: education.firstExistingDrivingLicenseNumber,
      secondExistingDrivingLicenseNumber: education.secondExistingDrivingLicenseNumber,
      firstExistingLicenseClassIssueDate: education.firstExistingLicenseClassIssueDate,
      secondExistingLicenseClassIssueDate: education.secondExistingLicenseClassIssueDate,
      practicalEducationFinishDate: education.practicalEducationFinishDate,
      theoreticalEducationFinishDate: education.theoreticalEducationFinishDate,
      costBearer: education.costBearer,
      costBearerTypeId: education.costBearerTypeId ? education.costBearerTypeId.id : null,
      costBearerEmail: education.costBearerEmail,
      theoryCourse: education.theoryCourse,
      instructors: education.instructors,
      billingAddress: billingAddressObject,
      initialPriceList: education.initialPriceList,
      archived: this.student?.archived,
      activeLivePayMandate: education.activeLivePayMandate,
      learnStep: this.student?.learnStep,
      contractAccepted: this.student?.contractAccepted,
    };

    await this.update({
      id: education.mainLicenseClass,
      data: dataObj,
      resource: `student-educations/${education.id}`,
    });

    if (this.id) {
      await this.fetchStudentEducationsWithGrantingTypeAndKeyNumbers(this.id);
      await this.fetchPriceAddonsByStudent(this.id);
    }

    if (this.success) {
      this.$root.$emit("refresh-students-table");
      this.$root.$emit("refresh-student-info");
    }
  }

  public get loading() {
    return this.avatarIsLoading || this.getIsLoading || this.internalLoading;
  }
}
